// src/SalonList.js
import React from "react";
import { salons } from "./data";
import "./ListServices.css";
import { MdOutlineLocationOn, MdOutlineStarOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";


const ListServices = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div className="salon-list">
          <h2>Sélectionnez un salon</h2>
          <p>
            Les meilleurs salons et instituts aux alentours de Agadir :
            Réservation en ligne
          </p>
          <div className="salons">
            {salons.map((salon, indx) => (
              <Link to={"/reserve-detail/" + indx}>
                <div key={salon.id} className="salon-card">
                  <img
                    src={salon.image}
                    alt={salon.name}
                    className="salon-image"
                  />
                  <div className="salon-details">
                    <h3>{salon.name}</h3>
                    <p>
                      <MdOutlineLocationOn />
                      {salon.address}
                    </p>
                    <p>
                      <MdOutlineStarOutline />
                      {salon.reviews}
                      {/*{salon.rating} ({salon.reviews} avis) • {salon.price}*/}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default ListServices;
