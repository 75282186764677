// src/data.js
export const salons = [
  {
    id: 1,
    name: "Talmarchit barber",
    address: "Elkharij, 87452 Massa",
    rating: 5.0,
    reviews: "Notre premier client ! service de première qualité et avis client très satisfaits",
    price: "€€",
    availability: "Jeudi 6 Juin 2024",
    image:
      "https://res.cloudinary.com/planity/image/upload/t_d_search,f_auto/uhmmqccxdilgcupljse3",
  },
];
