import React from "react";
import "./AboutUsClient.css";
import { IoQrCodeOutline } from "react-icons/io5";
import { LuScanLine } from "react-icons/lu";
import wave from "../../assets/images/waveTop.svg";
import waveBottom from "../../assets/images/waveBottom.jpg";

const ourTeam = [
  {
    id: "1",
    screen: "Profile1",
    image: "https://yourimageurl.com/image.jpg",
    subName: "CEO & Founder",
    name: "RSMOUKI Abdellah",
    description:
      "Behind every good software, there is a strong team that thinks about the success of the product. I've always dreamed of creating solutions that will change our everyday's life. Today, I have the capacity and the full energy to deliver clean products.",
  },
];

const AboutUs = () => {
  return (
    <div className="about-us ">
      <header className="header ">
        <img src={wave} alt="" />
        <div className="title_header">
          <h1 className="app-name">noobti</h1>
          <p className="slogan">adapted solutions to all type of use cases</p>
        </div>
      </header>

      <div className="container about_us_content">
        <section className="section">
          <h2 className="section-title">WHO WE ARE</h2>
          <p className="section-body">
            Noobti is the perfect solution for non mesurable waiting lines. It
            is designed based on three main processes: book from home, book on
            site and book for an emergency. For this three usecases, the manager
            have the full conrol of the line.
          </p>
        </section>

        <section className="section">
          <h2 className="section-title">WHAT WE DO</h2>
          <p className="section-body">
            - exclusive solutions for appointments managing
          </p>
          <p className="section-body">
            - adapted solutions to all type of use cases
          </p>
        </section>

        <section className="section">
          <h2 className="section-title">MEET OUR TEAM</h2>
          <div className="team">
            {ourTeam.map((item) => (
              <div className="team-member" key={item.id}>
                <div className="member-info">
                  <div>
                    <h3 className="member-name">{item.name}</h3>
                    <p className="member-title">{item.subName}</p>
                  </div>
                  <p className="member-description">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <div className="footer_wave">
        <img src={waveBottom} alt="" />
      </div>
    </div>
  );
};

export default AboutUs;
