import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { adapter } from "../../api"; // Import your axios adapter
import "./ManagerDetails.css";
import waveBottom from "../../assets/images/waveBottom.jpg";
import wave from "../../assets/images/waveTop.svg";

const regionInit = {
  latitude: 1.352083,
  longitude: 103.819839,
};

const ManagerDetails = () => {
  const [loading, setLoading] = useState(true);
  const [showToastError, setShowToastError] = useState(false);
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [region, setRegion] = useState(regionInit);

  useEffect(() => {
    const _getManager = async () => {
      try {
        const tracker = localStorage.getItem("tracker");
        if (!tracker) throw new Error("Tracker not found in localStorage");

        const { data: user } = await adapter.get(
          `/manager/?lineId=${tracker}`
        );
        setData(user);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setShowToastError(true);
      }
    };
    _getManager();
  }, []);

  const setData = (user) => {
    // Handling description field
    setDescription(
      typeof user.description === "object"
        ? user.description.text || "No description available"
        : user.description || "No description available"
    );

    // Handling phone field
    setPhone(
      typeof user.phone === "object"
        ? user.phone.number || "No phone available"
        : user.phone || "No phone available"
    );

    // Handling address field
    setAddress(
      typeof user.address === "object"
        ? user.address.coordinates
          ? `Lat: ${user.address.coordinates.lat}, Lng: ${user.address.coordinates.lng}`
          : "No address available"
        : user.address || "No address available"
    );

    // Handling email field
    setEmail(
      typeof user.email === "object"
        ? user.email.value || "No email available"
        : user.email || "No email available"
    );
  };

  const renderMap = () => (
    <div style={{ height: "300px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyB2KJ584PGvDDHpsYXalLHyc0JFR9e85UI" }}
        defaultCenter={{
          lat: region.latitude,
          lng: region.longitude,
        }}
        defaultZoom={15}
      >
        <Marker
          lat={region.latitude}
          lng={region.longitude}
          text="Manager Location"
        />
      </GoogleMapReact>
    </div>
  );

  return loading ? (
    <div className="loader">Loading...</div>
  ) : (
    <div className="background-home">
      <img src={wave} alt="" />

      <div className="scroll-view">
        <div className="item">
          <Item header="ABOUT" infoCount={1} info1={description} />
          <Item
            header="CONTACT"
            infoCount={2}
            info1={`PHONE : ${phone}`}
            info2={`EMAIL : ${email}`}
          />
          <Item header="ADDRESS" infoCount={1} info1={address} />
          {renderMap()}
        </div>
      </div>
      <img src={waveBottom} alt="" />
    </div>
  );
};

const Marker = ({ text }) => <div className="marker">{text}</div>;

const Item = ({ header, infoCount, info1, info2 }) => {
  return (
    <div className="item-container">
      <h3 className="header-text">{header}</h3>
      {infoCount === 1 ? (
        <p className="normal-text">{info1}</p>
      ) : (
        <div>
          <p className="normal-text">{info1}</p>
          <p className="normal-text">{info2}</p>
        </div>
      )}
    </div>
  );
};

export default ManagerDetails;
