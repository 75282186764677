import React, { useEffect, useState } from "react";
import { adapter } from "../../api"; // Import your axios adapter
import "./ManagerSchedule.css";
import waveBottom from "../../assets/images/waveBottom.jpg";
import wave from "../../assets/images/waveTop.svg";

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const ManagerSchedule = () => {
  const [loading, setLoading] = useState(true);
  const [showToastError, setShowToastError] = useState(false);
  const [workingDaysList, setWorkingDaysList] = useState([
    {
      id: 0,
      hospitalName: "PLEASE_WAIT",
    },
  ]);

  useEffect(() => {
    const _getManager = async () => {
      try {
        const token = localStorage.getItem("tracker");
        const { data: user } = await adapter.get(
          `/manager/schedule?lineId=${token}`
        );
        setData(user.schedule);
        setLoading(false);
      } catch (err) {
        setShowToastError(true);
        setLoading(false);
      }
    };
    _getManager();
  }, []);

  const setData = (schedule) => {
    if (schedule) {
      let list = [];
      for (const day of days) {
        let object = {
          id: day,
          hospitalName: day.toUpperCase(),
          address: `Morning: ${new Date(
            schedule[day].start
          ).getHours()}H ${new Date(
            schedule[day].start
          ).getMinutes()}M - ${new Date(
            schedule[day].pause
          ).getHours()}H ${new Date(schedule[day].pause).getMinutes()}M`,
          address2: `Afternoon: ${new Date(
            schedule[day].reprise
          ).getHours()}H ${new Date(
            schedule[day].reprise
          ).getMinutes()}M - ${new Date(
            schedule[day].end
          ).getHours()}H ${new Date(schedule[day].end).getMinutes()}M`,
          status: schedule[day].status ? "working" : "not working",
          isWorking: schedule[day].status ? true : false,
        };
        list.push(object);
      }
      setWorkingDaysList(list);
    }
  };

  return (
    <div className="background-home">
      <img src={wave} alt="" />
      <div className="schedule-container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          workingDaysList.map((item) => (
            <Item
              key={item.id}
              hospitalName={item.hospitalName}
              address={item.address}
              address2={item.address2}
              status={item.status}
              isWorking={item.isWorking}
            />
          ))
        )}
        {/* {showToastError && <p>Error loading schedule!</p>} */}
      </div>
      <img src={waveBottom} alt="" />
    </div>
  );
};

const Item = ({ hospitalName, address, status, address2, isWorking }) => {
  return (
    <div className={`schedule-item ${isWorking ? "working" : "not-working"}`}>
      <h3>{hospitalName}</h3>
      <p className="status">{status}</p>
      <p className={isWorking ? "" : "line-through"}>{address}</p>
      <p className={isWorking ? "" : "line-through"}>{address2}</p>
    </div>
  );
};

export default ManagerSchedule;
