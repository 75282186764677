import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import AboutUs from "./pages/AboutUsClient/AboutUsClient";
import AppointmentPage from "./pages/AppointmentPage/AppointmentPage";
import BookingDetails from "./pages/BookingDetails/BookingDetails";
import ClientScanned from "./pages/ClientScanned/ClientScanned";
import ListServices from "./pages/ListServices/ListServices";
import ManagerDetails from "./pages/ManagerDetails/ManagerDetails";
import ManagerSchedule from "./pages/ManagerSchedule/ManagerSchedule";
import Service from "./pages/Service/Service";
import Home from "./pages/home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ManagerDetails" element={<ManagerDetails />} />
        <Route path="/scanne" element={<AppointmentPage />} />
        <Route path="/scanned" element={<ClientScanned />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/ManagerSchedule" element={<ManagerSchedule />} />
        <Route path="/service-detail/:id" element={<Service />} />
        <Route path="/reserve" element={<ListServices />} />
        <Route path="/reserve-detail/:id" element={<BookingDetails />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
