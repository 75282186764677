import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import icon from "../../assets/images/appointment.svg";
import about from "../../assets/images/about.svg";
import days from "../../assets/images/days.svg";
import "./ClientScanned.css";
import { IoQrCodeOutline } from "react-icons/io5";
import { LuScanLine } from "react-icons/lu";
import wave from "../../assets/images/waveTop.svg";
import waveBottom from "../../assets/images/waveBottom.jpg";
import { MdLogout, MdMoreVert } from "react-icons/md";
import { adapter, BASE_URL } from "../../api"; // Import your axios adapter

function ClientScanned() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [node, setNode] = useState("");
  const [queueDetails, setQueueDetails] = useState(null);
  const [nodeDetails, setNodeDetails] = useState(0);
  const [loading, setLoading] = useState(true);
  const [qrCodeSvg, setQrCodeSvg] = useState(null);
  const [showQrModal, setShowQrModal] = useState(false);
  const [realTime, setRealTime] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const socketRef = useRef(null);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  const getRealTimeData = (node, line) => {
    socketRef.current = io("https://api.noobti.com", {
      transports: ["websocket"],
      reconnectionAttempts: 15,
      secure: true,
    });

    socketRef.current.on("connect", function () {
      socketRef.current.emit("connection");
      socketRef.current.emit("join", `lines:${line}`);

      // Handle real-time queue updates
      socketRef.current.on("queue", async (newMsg) => {
        setRealTime(newMsg);

        // Fetch and update current queue data from the API
        const { data: currentNoba } = await adapter.get(
          `/queue/node?node=${node}`
        );

        if (currentNoba.still === -1 || currentNoba.still === -2) {
          await handleCancelQueue();
          return;
        }

        setQueueDetails((prevDetails) => ({
          ...prevDetails,
          inQueue: {
            ...prevDetails.inQueue,
            count: newMsg.inQueue.count,
          },
          passed: {
            ...prevDetails.passed,
            count: newMsg.passed.count,
          },
        }));

        setNodeDetails(currentNoba);
      });
    });
  };

  useEffect(() => {
    const tracker = localStorage.getItem("tracker");

    adapter
      .get(`/manager/?lineId=${tracker}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching manager data:", error);
        setLoading(false);
      });

    adapter
      .get(`/queue/details/?lineId=${tracker}`)
      .then((response) => {
        const queueData = response.data;

        setQueueDetails(queueData);
        const nodeId = queueData.inQueue.node
          ? queueData.inQueue.node
          : queueData.passed.node;
        setNode(nodeId);
        localStorage.setItem("node", nodeId);

        adapter
          .get(`/queue/node?node=${nodeId}`)
          .then((nodeResponse) => {
            setNodeDetails(nodeResponse.data);
          })
          .catch((error) => {
            console.error("Error fetching node details:", error);
          });

        adapter
          .get(`/misc/users/${nodeId}/qr`)
          .then((response) => {
            setQrCodeSvg(response.data);
          })
          .catch((error) => {
            console.error("Error fetching QR Code:", error);
          });

        // Call the real-time data function
        getRealTimeData(nodeId, tracker);
      })
      .catch((error) => {
        console.error("Error fetching queue details:", error);
      });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const handleCancelQueue = () => {
    const tracker = localStorage.getItem("tracker");
    const nodeId = node;

    adapter
      .post(`/queue/node/cancel?lineId=${tracker}`, {
        nodeId: nodeId,
      })
      .then((response) => {
        localStorage.removeItem("tracker");
        localStorage.removeItem("node");
        navigate("/scanne");
      })
      .catch((error) => {
        console.error("Error canceling queue:", error);
      });
  };

  const handleShowQrCode = () => {
    if (qrCodeSvg) {
      setShowQrModal(true);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="card">
      <div className="header">
        <div className="icons container">
          <IoQrCodeOutline onClick={handleShowQrCode} />{" "}
          <LuScanLine style={{ opacity: 0.5 }} />
        </div>
        <img src={wave} alt="" />
        <div className="title_header">
          <img src={icon} alt="appointment" />
          <h2>You have an appointment</h2>
        </div>
      </div>

      <div className="container content-card">
        <div>
          <div className="doctor-info">
            <img
              src={`${BASE_URL}/files/${data.picture}/view`}
              alt={data.name}
              className="doctor-img"
            />
            <div>
              <h3>{data.name}</h3>
              <p>{data.profession}</p>
              <div className="details_doctor">
                <Link to="/ManagerDetails">
                  <img src={about} alt="about" />
                </Link>
                <Link to="/ManagerSchedule">
                  <img src={days} alt="ManagerSchedule" />
                </Link>
              </div>
            </div>
          </div>
          <div className="queue-info">
            <p>My Queue number</p>
            <h4>{nodeDetails?.still}</h4>
            <p>People In The Queue</p>
            <div className="queue-numbers">
              <button>In Queue: {queueDetails?.inQueue?.count}</button>
              <button>Passed: {queueDetails?.passed?.count}</button>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_wave">
        <img src={waveBottom} alt="" />
        <div className="content-footer">
          <p>Quit This Queue</p>
          <button className="quit-button" onClick={handleCancelQueue}>
            <MdLogout />
          </button>
        </div>
      </div>
      <div className="dropdown-container">
        <MdMoreVert onClick={handleDropdownToggle} className="more-icon" />
        {showDropdown && (
          <div className="dropdown_menu">
            <Link to="/aboutUs" onClick={handleCloseDropdown}>
              About Us
            </Link>
            <Link to="/" onClick={handleCloseDropdown}>
              Home
            </Link>
          </div>
        )}
      </div>

      {showQrModal && (
        <div className="qr-modal" onClick={() => setShowQrModal(false)}>
          <div className="qr-modal-content">
            {qrCodeSvg && (
              <div
                dangerouslySetInnerHTML={{ __html: qrCodeSvg }}
                className="qr-code-svg"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientScanned;
