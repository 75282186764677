import "./Header.css";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Modal from "react-bootstrap/Modal"; 
import { useState } from "react"; 
import logo from "../../assets/images/noobti-logo.svg";

function Header() {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true); 
  };

  const handleCloseModal = () => {
    setShowModal(false); 
  };

  return (
    <>
      <Navbar expand="lg" className="header_top container">
        <Navbar.Brand href="/">
          <img src={logo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0 gap-4 links-principal"
            navbarScroll
          >
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/service-detail/1">Métiers</Nav.Link>
            <Nav.Link href="/reserve-detail/1">Clients</Nav.Link>
            <Nav.Link href="/reserve">Ressources</Nav.Link>
          </Nav>
          <div className="d-flex gap-3 align-items-center">
            <Button className="btn-header" onClick={handleShowModal}>
              S'enregistrer dans une file d'attente
            </Button>
          </div>
        </Navbar.Collapse>
      </Navbar>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body className="text-center custom-modal-body">
          <p>
            Cette fonctionnalité est disponible uniquement pour les téléphones
            mobiles.
          </p>
       
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
