import React from "react";
import "./BookingDetails.css";
import { MdOutlineLocationOn, MdOutlineStarOutline } from "react-icons/md";
import Slide from "../../components/Slide/Slide";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

const BookingDetails = () => {
  const salon = {
    name: "Talmarchit barber",
    address: "Elkharij, 87452 Massa",
    rating: 5.0,
    reviews:
      "Notre premier client ! service de première qualité et avis client très satisfaits",
    price: "€€",
    availability: "Jeudi 6 Juin 2024",
    image:
      "https://res.cloudinary.com/planity/image/upload/t_d_search,f_auto/uhmmqccxdilgcupljse3",
  };
  return (
    <>
      <Header />
      <div className="booking">
        <div className="container">
          <div className="salon-details">
            <h3>{salon.name}</h3>
            <p>
              <MdOutlineLocationOn />
              {salon.address}
            </p>
            <p>{salon.reviews}</p>
          </div>
          <Slide />
          <div className="booking-detail">
            <h3>Réserver en ligne pour un RDV chez Talmarchit barber</h3>
            <p>Liste d'attente en temps réel</p>
            <div className="prix-and-date">
              <div className="prix">
                <h3>Choix de la prestation</h3>
                <h4>COUPES COURTES</h4>
                <p>
                  Les coupes comprennent un diagnostic personnalisé, un
                  shampooing adapté et le coiffage.
                </p>
                <div>
                  <div className="service-card">
                    <div>
                      <p>Dégradé</p>
                      <span>
                        Les coiffeurs sont formés pour offrir des coupes
                        modernes et adaptées, en tenant compte du type de
                        cheveux, de structure faciale et du style personnel.
                      </span>
                    </div>
                    <span>30min</span> {/* - 30$*/}
                  </div>
                  <div className="service-card">
                    <div>
                      <p>Soins</p>
                      <span>
                        Les soins capillaires diversifiés qui améliorent la
                        santé et l'apparence des cheveux, tels que les
                        traitements nourrissants en profondeur pour les cheveux
                        secs et cassants.
                      </span>
                    </div>
                    <span>15min</span> {/* - 30$*/}
                  </div>
                  <div className="service-card">
                    <div>
                      <p>Coloration</p>
                      <span>
                        La coloration qui consiste à changer de teinte ou de
                        couvrir les cheveux gris.
                      </span>
                    </div>
                    <span>45min</span> {/* - 30$*/}
                  </div>
                  <div className="service-card">
                    <div>
                      <p>shampooing</p>
                      <span>
                        Permettre de laver en profondeur et de nourrir le cheveu
                        avec les vitamines, minéraux et huiles dont il a besoin.
                      </span>
                    </div>
                    <span>15min</span> {/* - 30$*/}
                  </div>
                  <div className="service-card">
                    <div>
                      <p>Épilation du visage</p>
                      <span>
                        L’épilation du duvet et des sourcils font également
                        partie des prestations.
                      </span>
                    </div>
                    <span>15min</span> {/* - 30$*/}
                  </div>
                </div>
              </div>
              <div className="date">
                <h4>Horaires d'ouverture</h4>
                <div className="date-detail">
                  <div className="card-date">
                    <p>Lundi</p>
                    <span className="ferme">Fermé</span>
                  </div>
                  <div className="card-date">
                    <p>Mardi</p>
                    <span>10:00 - 22:00</span>
                  </div>
                  <div className="card-date">
                    <p>Mercredi</p>
                    <span>10:00 - 22:00</span>
                  </div>
                  <div className="card-date">
                    <p>Jeudi</p>
                    <span>10:00 - 22:00</span>
                  </div>
                  <div className="card-date">
                    <p>Vendredi</p>
                    <span>10:00 - 22:00</span>
                  </div>
                  <div className="card-date">
                    <p>Samedi</p>
                    <span>10:00 - 22:00</span>
                  </div>
                  <div className="card-date">
                    <p>Dimanche</p>
                    <span className="ferme">Fermé</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BookingDetails;
