import "./Faq.css";
import Accordion from "react-bootstrap/Accordion";

function Faq() {
  const faq = [
    {
      Q: "Comment fonctionne Planity pour moi ?",
      R: "Après la création de votre page salon personnalisée sur le portail de réservation , vous allez avoir la possibilté de gérer la liste d'attente d'une manière fluide. vous pouvez ajouter, supprimer ou passer des rendez-vous en toute simplicité. Vous avez également la possibilité de lancer, d’arrêter ou de réinitialiser la file d’attente à tout moment.",
    },
    {
      Q: "Comment fonctionne Planity pour mes clients ?",
      R: "Après la création de votre page salon personnalisée sur le portail de réservation , vos clients pourront réserver vos prestations en un seul clic, selon vos créneaux disponibles. Finies les interruptions téléphoniques, les RDV sont gérés en toute autonomie, toujours selon vos contraintes.",
    },
    {
      Q: "Comment installer l'application ?",
      R: "Remplissez vos informations personnelles via le formulaire de contact ci-dessus pour contacter nos consultants. Ils vont vous contacter pour vous expliquer notre service et puis vous aller avoir accès à votre compte personnel. Pour télécharger l'application pour Android ou IOS, utilisez les icon Google play et Applestore",
    },
    {
      Q: "Comment mes clients peuvent installer l'application ?",
      R: "Ils peuvent télécharger directement l'application pour Android ou IOS. Pour prendre un rendez-vous, il suffit de scanner votre QRcode",
    },
  ];
  return (
    <div className="container faq">
      <h2>Les questions fréquentes</h2>
      <Accordion>
        {faq.map((el, index) => {
          return (
            <Accordion.Item eventKey={index}>
              <Accordion.Header>{el.Q}</Accordion.Header>
              <Accordion.Body>{el.R}</Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
}

export default Faq;
