import React, { useEffect, useState } from "react";
import axios from "axios";
import wave from "../../assets/images/waveTop.svg";
import icon from "../../assets/images/noAppointment.svg";
import { IoQrCodeOutline } from "react-icons/io5";
import { LuScanLine, LuUpload } from "react-icons/lu";
import { MdMoreVert } from "react-icons/md"; // Import the more icon
import { BrowserMultiFormatReader } from "@zxing/library";
import "./AppointmentPage.css";
import waveBottom from "../../assets/images/waveBottom.jpg";
import { Link, useNavigate } from "react-router-dom";
import { adapter } from "../../api"; // Ensure BASE_URL is imported

const QRCodeScanner = () => {
  const [scanResult, setScanResult] = useState(null);
  const [qrCodeSvg, setQrCodeSvg] = useState(null);
  const [showQrModal, setShowQrModal] = useState(false);
  const [isCameraRunning, setIsCameraRunning] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // State for dropdown visibility
  const navigate = useNavigate();
  const codeReader = new BrowserMultiFormatReader();

  const handleScan = (result) => {
    if (result) {
      setScanResult(result.getText());
    }
  };

  const handleError = (err) => {
    // Handle error if needed
  };

  useEffect(() => {
    if (scanResult) {
      const cleanedScanResult = scanResult.replace("lines:", "");

      const sendScanResult = async () => {
        try {
          const response = await adapter.post(
            `/queue/connect`, // Use adapter to make the POST request
            {
              lineId: cleanedScanResult,
            }
          );
          localStorage.setItem("tracker", response.data.tracker);
          navigate("/scanned");
        } catch (error) {
          console.log("Error sending data:", error);
        }
      };

      sendScanResult();
    }
  }, [scanResult, navigate]);

  const startCamera = async () => {
    try {
      const videoInputDevices = await codeReader.listVideoInputDevices();
      const firstDeviceId = videoInputDevices[0].deviceId;

      codeReader.decodeFromVideoDevice(
        firstDeviceId,
        "video",
        (result, err) => {
          if (result) {
            handleScan(result);
          }
          if (err) {
            handleError(err);
          }
        }
      );

      setIsCameraRunning(true);
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  };

  const stopCamera = () => {
    codeReader.reset();
    const videoElement = document.getElementById("video");
    if (videoElement && videoElement.srcObject) {
      const stream = videoElement.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoElement.srcObject = null;
    }
    setIsCameraRunning(false);
    setShowIcon(false);
  };

  useEffect(() => {
    return () => {
      codeReader.reset();
    };
  }, [codeReader]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = async () => {
          try {
            const result = await codeReader.decodeFromImage(img);
            setScanResult(result.getText());
          } catch (err) {
            console.error("Error scanning image:", err);
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  return (
    <div className="noubty-scaner">
      <div className="header">
        <div className="icons container">
          <IoQrCodeOutline style={{ opacity: 0.5 }} />
          <LuScanLine
            onClick={() => {
              setShowIcon(false);
              startCamera();
            }}
          />
        </div>
        <img src={wave} alt="" />
        <div className="title_header">
          <img src={icon} alt="no appointment" />
          <h2>You have no appointment</h2>
        </div>
      </div>
      <div className="container content-scan">
        <div>
          {!isCameraRunning &&<h1>Scan a Manager’s QR Code To get an Appointment</h1>}
          <div className="big-icon-scan">
            {showIcon && <LuScanLine onClick={() => setShowIcon(false)} />}
          </div>

          {!showIcon && (
            <div className="container-btn-scanne">
              <div className="camera-controls">
                {!isCameraRunning && (
                  <button onClick={startCamera}>
                    <LuScanLine /> Start Camera
                  </button>
                )}
              </div>
              {!isCameraRunning && (
                <label className="upload-button">
                  <LuUpload />
                  <p>Upload</p>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </label>
              )}
              {isCameraRunning && (
                <div className="camera-controls">
                  <video id="video" style={{ width: "100%" }} />
                  <button onClick={stopCamera}>
                    <LuScanLine /> Stop Camera
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="footer_wave">
        <img src={waveBottom} alt="" />
        <div className="dropdown-container">
          <MdMoreVert onClick={handleDropdownToggle} className="more-icon" />
          {showDropdown && (
            <div className="dropdown_menu">
              <Link to="/aboutUs" onClick={handleCloseDropdown}>
                About Us
              </Link>
              <Link to="/" onClick={handleCloseDropdown}>
                Home
              </Link>
            </div>
          )}
        </div>
      </div>

      {showQrModal && (
        <div className="qr-modal" onClick={() => setShowQrModal(false)}>
          <div className="qr-modal-content">
            {qrCodeSvg && (
              <div
                dangerouslySetInnerHTML={{ __html: qrCodeSvg }}
                className="qr-code-svg"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default QRCodeScanner;
