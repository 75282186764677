import React from 'react'
import './Service.css'
import ListServices from '../ListServices/ListServices';
import { Link, useParams } from 'react-router-dom';
import barbershopImage from '../../assets/images/reading.jpg';
import Carousel from "react-bootstrap/Carousel";
import image from '../../assets/images/image copy.png'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Service = () => {
  const { id } = useParams();
  console.log(id);
  return (
    <>
      <Header />
      <Carousel className="slide-servide">
        <Carousel.Item>
          <img src={image} alt="img" />
          <Carousel.Caption />
        </Carousel.Item>
        <Carousel.Item>
          <img src={image} alt="img" />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={image} alt="img" />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="service">
        <div className="container">
          <div className="description">
            <p>
              Plusieurs solutions permettent de réduire les fichiers d'attente
              et d'améliorer l'expérience dans les salons de coiffure. La mise
              en place d'un système de rendez-vous en ligne, via des plateformes
              numériques ou des applications mobiles, permet aux clients de
              choisir un créneau horaire adapté et de réduire l'attente. De
              plus, la gestion du flux de clients en temps réel, grâce à des
              logiciels dédiés, permet de suivre l'affluence et d'ajuster les
              plannings. Une communication proactive avec les clients, en les
              informant des délais d'attente augmentés ou en leur proposant des
              alternatives comme revenir plus tard ou recevoir une alerte par
              SMS, améliore également leur perception de l'attente.
              L'optimisation des plannings-issue d'un contrôle régulier de la
              durée des services-évite une surcharge. Enfin, ajouter une touche
              supplémentaire à la salle d'attente avec des magazines, une
              connexion Wi-Fi, des boissons ou des fauteuils de massage rendra
              le processus d'attente un peu plus confortable pour eux.
            </p>
            <article>
              <h2>
                Pourquoi pensé à une application de gestion de file d'attente ?
              </h2>
              <section>
                <img src={barbershopImage} alt="barber shop image" />
              </section>
              <section>
                <div>
                  <h3>
                    Gestion de Files d'Attente dans les Salons de Coiffure : Le
                    Défi à Relever
                  </h3>
                  <p>
                    La file d'attente est un problème récurrent dans la majorité
                    des salons de coiffure, surtout dans les salons de renommée
                    ou centraux. While the waiting may be long and uncomfortable
                    for the customers, from the perspective of the hairdressers,
                    it is a logistical challenge that requires good
                    organization. Cette étude se concentre sur les causes de ce
                    phénomène, de ses impacts, et des solutions que peuvent
                    envisager les salons de coiffure pour optimiser l'expérience
                    client.
                  </p>
                </div>
              </section>
              <section>
                <h3>
                  Qu'est-ce qui est à l'origine de la formation des files
                  d'attente dans les salons de coiffure ?
                </h3>
                <div>
                  <p>
                    Plusieurs facteurs peuvent expliquer la formation des files
                    d'attente dans les salons de coiffure : L'absence de prise
                    de rendez-vous : Certains salons fonctionnent sans
                    rendez-vous, ce qui crée des files d'attente importantes,
                    notamment aux heures de pointe (fin de semaine, soirée,
                    vacances scolaires). Temps de service variable : Chaque
                    client a des besoins différents, qu'il s'agisse d'une coupe
                    simple ou de traitements plus longs comme les colorations,
                    lissages ou permanentes. Cette variabilité rend difficile
                    une planification précise. Sous-effectif : Dans certains
                    salons, le manque de personnel qualifié peut aggraver les
                    temps d'attente. En cas de forte affluence, les coiffeurs
                    peuvent se retrouver débordés. Problèmes de gestion : Une
                    mauvaise gestion du flux de clients, comme des retards de
                    rendez-vous ou une sous-évaluation du temps nécessaire pour
                    certains services, contribue aussi à augmenter la durée de
                    ces délais.
                  </p>
                </div>
              </section>
              <section>
                <h3>
                  {" "}
                  L'effet de la file d'attente sur les expériences des clients
                  Une attente prolongée peut nuire à l'image du salon et nuire à
                  la satisfaction des clients. Voici quelques effets courants :
                </h3>
                <div>
                  <p>
                    Frustration : les clients qui attendent longtemps peuvent
                    éprouver de la frustration, surtout s'ils se sentent
                    abandonnés lorsque personne ne les a informés d'éventuels
                    retards à leur arrivée.
                  </p>
                  <p>
                    Perte de clients : si un salon en vient à se faire une
                    réputation d'avoir des délais longs, le risque est là de
                    voir ses clients le désertant pour d'autres concurrents
                    offrant des temps d'attente plus courts ou de meilleure
                    gestion.
                  </p>
                  <p>
                    Stress sur le personnel : une surcharge de travail, liée à
                    une mauvaise gestion des flux, peut causer du stress auprès
                    des coiffeurs, notamment leur productivité, ce qui impacte
                    la qualité.
                  </p>
                </div>
              </section>
              <section>
                <h3>
                  {" "}
                  Noobti est la solution pour les files d'attente non
                  mesurables.
                </h3>
                <div>
                  <p>
                    La gestion des dossiers d'attente dans les salons de
                    coiffure est un enjeu majeur pour offrir une expérience
                    client de qualité. Dans certains cas, des solutions
                    innovantes concernant la prise de rendez-vous en ligne ou la
                    gestion des flux en temps réel peuvent réduire les files
                    d'attente et fidéliser une clientèle souvent exigeante.
                    L'optimisation des fichiers d'attente devient alors un atout
                    pour se démarquer dans un secteur où la concurrence est
                    forte.
                  </p>
                  <p>
                    Noobti propose une solution de gestion des files d'attente
                    imprévisibles grâce à un système basé sur trois processus
                    clés : la réservation à domicile, la réservation sur place
                    et la réservation pour les urgences. En améliorant
                    continuellement ces processus, Noobti garantit une
                    expérience client optimisée, en organisant tous les clients
                    sur une liste numérique centralisée.
                  </p>
                </div>
              </section>
              <div className="list_container">
                <ListServices />
              </div>
              <section className="bottom-links">
                <h2>Recherches fréquentes</h2>
                <ul>
                  <li>
                    <Link to="/reserve-detail/1">Coiffure jeunes</Link>
                  </li>
                </ul>
              </section>
            </article>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Service