import axios from "axios";
// const https = require('https');

// const BASE_URL = 'http://localhost:3000/api/v1';
const BASE_URL = "https://api.noobti.com/api/v1";
// const BASE_URL = 'https://167.71.60.10:80/api/v1';
const TOKEN = "token";

/*const agent = new https.Agent({
  rejectUnauthorized: false,
});*/

const adapter = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
  },
  // httpsAgent: agent,
});

export { adapter, BASE_URL, TOKEN };
