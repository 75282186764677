import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css"; 
import logo from "../../assets/images/noobti-logo.svg"; 
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaTiktok,
} from "react-icons/fa";


const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={4} className="footer-column first-column">
            <img src={logo} alt="Logo" className="footer-logo" />
            <div className="icons-social">
              <FaFacebookSquare cursor="pointer" onClick={() => window.open('https://www.facebook.com/Noobti/', '_blank')} />
              <FaInstagramSquare cursor="pointer" onClick={() => window.open('https://www.instagram.com/noobti.official/', '_blank')} />
              <FaLinkedin cursor="pointer" onClick={() => window.open('https://www.linkedin.com/company/noobti/', '_blank')} />
            </div>
            <div>
              <p>
                <a href="mailto:noobti.founders@gmail.com">noobti.founders@gmail.com</a>
              </p>
              <p>
                <a href="https://wa.me/your-whatsapp-number">+2128823948</a>
              </p>
            </div>
          </Col>
          <Col md={4} className="footer-column">
            <h5>Ressources</h5>
            <ul>
              <li>
                <a href="#link4">Les cas d'utilisation efficaces pour Noobti</a>
              </li>
              <li>
                <a href="#link5">Comment configurer votre profile dans quelques minutes ?</a>
              </li>
              <li>
                <a href="#link6">La sécurité des données utilisateur est-elle considérée comme critique ?</a>
              </li>
            </ul>
          </Col>
          <Col md={4} className="footer-column">
            <h5>Témoignages</h5>
            <ul>
              <li>
                <a href="#link7">Mon coiffeur préférer m'a simplifié la vie</a>
              </li>
              <li>
                <a href="#link8">Pas besoin d'aller chez le médecin à 6h !</a>
              </li>
              <li>
                <a href="#link9">Plusieurs cas d'utilisation dans mon établissement, Ça change vraiment la vie</a>
              </li>
            </ul>
          </Col>
        </Row>
      <div className="copyright">
        © Noobti - 2024 copyright all right reserved |{" "}
        <a href="https://sites.google.com/view/noobti-privacy-policy/accueil">
          Politique de confidentialité
        </a>
      </div>
      </Container>
    </footer>
  );
};

export default Footer;
